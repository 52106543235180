import React from 'react'
import styled from 'styled-components'
import { Typography, TextField, Button } from '@material-ui/core'
import Ratings from 'react-ratings-declarative'
import queryString from 'query-string'
import Swal from 'sweetalert2'
import { useSubmitRatingMutation } from '../../apollo/generated'
import { navigate } from 'gatsby'

const JobRating = () => {
	const code =
		typeof window !== 'undefined'
			? (queryString.parse(location.search).code as string)
			: ''
	const [mutate] = useSubmitRatingMutation()
	const [comment, setComment] = React.useState('')
	const [rating, _setRating] = React.useState(0)
	const setRating = async (newRating?: number) => {
		try {
			if (!newRating && !rating) {
				return Swal.fire('Please Give a Rating', undefined, 'warning')
			}
			newRating && _setRating(newRating)
			await mutate({
				variables: {
					code,
					ratingGiven: newRating || rating,
					comment: comment || undefined
				}
			})
			!newRating &&
				Swal.fire(
					'Rating Submitted!',
					'Feel free to come back to this page at any time to update your review',
					'success'
				).then(() => navigate('/'))
		} catch (e) {
			Swal.fire('Error Submitting Rating', e.message, 'error')
		}
	}

	return (
		<Container>
			<Internal>
				<Typography align="center" gutterBottom variant="h5">
					Job Feedback
				</Typography>
				<Typography
					align="center"
					gutterBottom
					variant="subtitle1"
					style={{ marginBottom: 12 }}
				>
					How was your experience working with the other party?
				</Typography>
				<Ratings
					rating={rating}
					changeRating={r => setRating(r)}
					widgetRatedColors="#8B6137"
					widgetHoverColors="#E2B985"
				>
					<Ratings.Widget />
					<Ratings.Widget />
					<Ratings.Widget />
					<Ratings.Widget />
					<Ratings.Widget />
				</Ratings>
				<TextField
					style={{ alignSelf: 'stretch', marginTop: 8, marginBottom: 8 }}
					multiline
					rows={4}
					variant="outlined"
					placeholder="Additional Feedback"
					value={comment}
					onChange={e => setComment(e.target.value as string)}
				/>
				<Button
					style={{ alignSelf: 'flex-end', padding: '6px 24px' }}
					onClick={() => setRating()}
				>
					Save
				</Button>
			</Internal>
		</Container>
	)
}

export default JobRating

const Container = styled.div`
	display: flex;
	position: absolute;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	> * {
		margin: 8px;
	}
`

const Internal = styled.div`
	background-color: white;
	border-radius: 4px;
	padding: 20px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
