import React from 'react'

import SEO from '../components/seo/seo'
import MainWrapper from '../components/ui/MainWrapper'
import JobRating from '../components/jobRating'

const JobRatingPage = () => (
	<MainWrapper>
		<SEO
			title="Rate Job"
			keywords={['rate job', '']}
		/>
		<JobRating />
	</MainWrapper>
)

export default JobRatingPage
